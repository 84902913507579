<!-- // eslint-disable-next-line vue/multi-word-component-names -->
<template>
  <v-form ref="loginForm" v-model="valid" class="login-form" id="login-form">
    <section class="form-page">
      <h4 class="text-center form-header">Login</h4>
      <p class="text-center error--text" v-if="text_snack">
        {{ text_snack }}
      </p>
      <label for="email">Email </label>
      <v-text-field
        v-model="email"
        single-line
        :rules="[validationRules.required, validationRules.validEmail]"
        required
        solo
        :placeholder="$t('Please enter your email')"
      ></v-text-field>

      <label for="password">Password </label>
      <v-text-field
        v-model="password"
        single-line
        :rules="[validationRules.required, validationRules.validPassword]"
        required
        type="password"
        solo
        :placeholder="$t('Please enter your Password')"
      ></v-text-field>
      <div class="text-center">
        <v-btn class="mr-4 main-btn" @click="login"> Login </v-btn>
      </div>

      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snack_color"
        rounded="pill"
        top
      >
        {{ text_snack }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </section>
  </v-form>
</template>

<script>
import { validationMixin } from "../mixins/validation-mixin";
import HttpService from "../services/HttpService.js";
import ACL from "../acl";
export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      email: "",
      password: "",
      snackbar: false,
      text_snack: "",
        timeout: 10000,
      snack_color: "",
      selectedRole: "",
      rolesAfterParse: "",
      isSuperAdmin: ACL.Role("Super-Admin"),
      isWarehouseStaff: ACL.Role("Warehouse-Staff"),
      isCanteenStaff: ACL.Role("Canteen-Staff"),
    };
  },
  methods: {
    login() {
      if (this.valid) {
        // send to login api
        HttpService.post("login", {
          email: this.email,
          password: this.password,
        }).then((res) => {
          console.log("the res ", res);
          if (res.data.status.error == false) {
            localStorage.setItem("roles", JSON.stringify(res.data.data.roles)); // arr to str
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("school_logo", res.data.data.school_logo);
            localStorage.setItem("auth_id", res.data.data.auth_id);
            localStorage.setItem("auth_name", res.data.data.auth_name);
            // console.log("localStorage = ", localStorage.auth_id);
            this.selectedRole = res.data.data.roles;
            localStorage.setItem(
              "selectedRole",
              JSON.stringify(this.selectedRole)
            );
            // localStorage.getItem()
            // console.log(
            //   "is",
            //   localStorage.getItem("selectedRole").includes("Parent")
            // );
            this.rolesAfterParse = JSON.parse(localStorage.selectedRole);
            window.location = "/"; // with refresh
          } else {
            this.text_snack = res.data.status.message;
          }
        });
      } else {
        this.$refs.loginForm.validate();
      }
    },
  },
  mounted() {
    // console.log("VUE_APP_API_URL", process.env.VUE_APP_API_URL);
  },
  created() {
    // this.$http
    //   .get("https://jsonplaceholder.typicode.com/photos")
    //   .then((response) => {
    //     console.log(response.data);
    //     this.photos = response.data;
    //   });
  },
};
</script>

<style scoped>
#login-form {
  margin: 30% 0;
}
</style>
