<template>
  <v-container>
    <v-row class="headerTable-div2" style="width: 65%">
      <v-col md="3">
        <h3
          class="custom-header main-color"
          style="margin-left: 0px !important; padding-top: 20px !important"
        >
          {{ title }}
        </h3>
      </v-col>
      <!-- <v-row> -->
      <v-col md="3">
        <v-autocomplete
          id="autocompelet"
          style="height: 34px !important; padding-top: 20px !important"
          v-model="search.searchType"
          label="Search by"
          :items="['Parent Name ', 'Student Name', 'Student ID']"
        ></v-autocomplete>
      </v-col>
      <v-col md="5">
        <v-card-text>
          <div class="search-container" style="display: flex !important">
            <v-text-field
              style="flex: 1 !important"
              v-model="search.searchText"
              label="Enter Name or ID"
              hide-details
              single-line
              prepend-inner-icon="mdi-magnify"
              solo
              class="searchInput"
              @keydown.enter.prevent="Search(1)"
            ></v-text-field>
            <v-btn
              style="
                text-transform: capitalize;
                margin-left: -75px !important ;
                margin-top: 5px !important;
                box-shadow: none !important;
                color: #5a86ff !important;
              "
              @click="Search(1)"
            >
              Go >
            </v-btn>
          </div>
        </v-card-text>
      </v-col>
      <v-col md="1">
        <v-dialog v-model="dialog" persistent max-width="410px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="isAddButtonDisabled"
              small
              style="
                background-color: #5a86ff;
                margin-top: 20px !important;
                margin-bottom: 0px !important;
              "
              @click="getSelectedStudents"
              v-bind="attrs"
              v-on="on"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_52_2217)">
                  <path d="M22 8L18 4V7H3V9H18V12L22 8Z" fill="white" />
                  <path d="M2 16L6 20V17H21V15H6V12L2 16Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_52_2217">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </v-btn>
          </template>

          <!-- TransFer for children -->
          <v-card>
            <v-card-title>
              <span>
                <h1 class="form-title">
                  {{ $t(formTitle) }}
                </h1>
              </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="valid" ref="modalForm">
                  <v-row v-for="(val, index) in studentData" :key="index">
                    <!-- <v-col cols="4"> -->
                    <v-col cols="4">
                      <label
                        class="currency"
                        style="font-weight: bold !important"
                        >{{ selectedStudentName }}</label
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-row>
                        <v-text-field
                          style="
                            width: 10px !important;
                            border: black !important;
                            margin-bottom: 10px !important;
                          "
                          v-model="studentData[index].amount"
                          label=""
                          solo
                          @keyup="calculatePoints"
                        ></v-text-field>

                        <span class="currency">&nbsp; SAR</span>
                      </v-row>
                    </v-col>
                    <v-col cols="1"></v-col>

                    <v-col cols="3">
                      <v-row>
                        <label style="padding-top: 15px"> = &nbsp; </label>
                        <label style="padding-top: 15px"
                          >{{ equivelantPoints }}
                        </label>
                        <span class="currency">&nbsp; pts</span>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions style="padding-bottom: 30px !important">
              <v-spacer></v-spacer>
              <v-btn
                style="text-transform: capitalize"
                class="modal-btn-cancel"
                @click="close"
                >{{ $t("Cancel") }}</v-btn
              >
              <v-btn
                style="text-transform: capitalize"
                class="modal-btn-save"
                @click="transfer"
                :disabled="!valid"
                >{{ $t("Confirm") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <!-- </v-row> -->
      <!-- ----------------Dialog of transfers----------------- -->
      <template>
        <v-row justify="center">
          <v-col class="text-right"> </v-col>
        </v-row>
      </template>
      <!-- ----------------Dialog of transfers----------------- -->
    </v-row>

    <v-row>
      <v-col cols="8" style="position: relative">
        <v-data-table
          v-model="selected"
          :headers="table.headersData"
          :items="students"
          :options.sync="options"
          class="elevation-1 orderTable"
          item-key="id"
          hide-default-footer
          show-select
          single-select="true"
        >
        </v-data-table>

        <!-- <v-data-table
          v-model="selected"
          :headers="table.headersData"
          :items="students"
          :options.sync="options"
          class="elevation-1 orderTable"
          item-key="id"
          hide-default-footer
          show-select
          single-select="true"
        >
        </v-data-table> -->
        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.length"
            :circle="true"
            :page="pagination.page"
            :total-visible="pagination.totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </div>
      </v-col>
      <EmptySpace></EmptySpace>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snack_color"
      rounded="pill"
      top
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import EmptySpace from "../components/EmptySpace.vue";
import HttpService from "../services/HttpService.js";

export default {
  components: {
    EmptySpace,
  },

  data() {
    return {
      studentData: [],
      selectedStudentName: "",
      pagination: {
        page: 1,
        length: 0,
        totalVisible: "",
      },
      options: {
        itemsPerPage: 15,
      },
      equivelantPoints: 0,
      formTitle: "Recharge",
      selectingType: false,
      selected: [],
      dialog: false,
      valid: false,
      snackbar: false,
        timeout: 10000,
      snack_color: "success",
      message: "",
      studentTransfer: [],
      auth_id: localStorage.getItem("auth_id"),
      defaultItem: {
        id: "",
        name: "",
        amount: 0,
      },
      isSelected: "",
      children: 0,
      students: [],
      search: {
        searchType: "",
        searchText: "",
      },
      searchResult: "",
      selectedStudentID: -1,
      title: "Recharges",
      table: {
        headersData: [
          {
            text: this.$i18n.t("Student Name"),
            value: "name",
            sortable: false,
          },
          {
            text: this.$i18n.t("Student ID"),
            value: "code",
            sortable: false,
          },
          {
            text: this.$i18n.t("Mother Name"),
            value: "mother name",
            sortable: false,
          },
        ],
      },
    };
  },
  methods: {
    Search(searchPage) {
      // let queryString = "?page=" + this.pagination.page;
      this.pagination.page = searchPage;
      let queryString = "?page=" + searchPage;
      HttpService.post("accountant/" + queryString, this.search, true).then(
        (response) => {
          // HttpService.post("accountant/", this.search, true).then((response) => {
          console.log("response = ", response);
          this.searchResult = response.data.data.data;
          this.students = [];
          this.setPaginationParameters(response);
          for (var i = 0; i < this.searchResult.length; i++) {
            this.students.push(this.searchResult[i]);
          }
          console.log("students from search = ", this.students);
        }
      );
    },

    getSelectedStudents() {
      this.studentData = [];
      this.studentData.push(this.selected[0]);
      this.studentData[0]["amount"] = "";
      this.studentData[0]["points"] = "";
      let names = this.studentData[0].name.split(" ");
      this.selectedStudentName = names[0];
      this.calculatePoints();
    },

    setPaginationParameters(response) {
      console.log("response in pagenation = ", response);
      this.pagination.length = response.data.data.last_page;
      console.log(this.pagination.length);
      if (this.pagination.length > 5) this.pagination.totalVisible = 5;
    },

    calculatePoints() {
      let equivelant = 0;
      if (
        /^(0|[1-9]\d*)(\.\d+)?$/.test(this.studentData[0].amount) === true &&
        this.studentData[0].amount != ""
      ) {
        equivelant = 20 * this.studentData[0].amount;
      }
      this.equivelantPoints = equivelant >= 0 ? equivelant : 0;
    },

    close() {
      this.studentData = [];
      this.selected = [];
      this.dialog = false;
      this.$refs.modalForm.resetValidation();
      console.log("iam here in close() ...");
    },

    transfer() {
      if (this.valid) {
        // send the amount to backend
        HttpService.post(
          "accountant/transferByAccountant",
          this.studentData[0],
          true
        ).then((response) => {
          console.log("response = ", response);
          this.close();
          this.message = response.data.status.message;
          if (response.data.status.error == false) {
            this.message = "Amount transferred successfully";
            this.snack_color = "success";
            this.snackbar = true;
            this.close();
          } else {
            this.snack_color = "error";
            this.snackbar = true;
          }
        });
      } else {
        this.$refs.modalForm.validate();
      }
    },

    //here
  },
  created() {},

  watch: {
    "pagination.page": {
      handler() {
        this.Search(this.pagination.page);
        console.log("Watch is work!");
      },
      deep: true,
    },
  },

  computed: {
    isAddButtonDisabled() {
      // eslint-disable-next-line no-constant-condition
      return true ? this.selected.length <= 0 : false;
    },
  },

  updated() {},
};
</script>

<style scoped>
.cart-title {
  width: 438px;
  height: 23px;
  padding-top: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 23px;
  color: #7297ff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.v-data-table tr {
  background-color: #ffffff;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-radius: 7px;
  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
  text-align: center !important;
}

/* tr.v-data-table__selected {
  background: #7d92f5 !important;
} */

.form-title {
  color: #7297ff !important;
  width: 165px !important;
  height: 27px !important;

  /* font-family: "Open Sans" !important; */
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  /* line-height: 27px !important; */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.currency {
  padding-top: 15px !important;
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  /* line-height: 19px; */
  color: #979797;
}

.v-input__slot {
  align-items: center;
  display: flex;
  margin: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition-property: height, min-height;
  width: 50%;
}
.v-messages theme--light {
  margin-bottom: 0 !important;
}

.search-container {
  display: flex;
  flex-flow: row;
}
</style>
