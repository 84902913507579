<template>
  <v-col cols="12" md="3">
    <div class="wallet-container">
      <h3 class="cart-title">My Points</h3>
      <div class="wallet-inside-container">
        <v-row class="suitable">
          <span class="right-span" style="margin-bottom: 100px"
            >Current Points</span
          >
          <span class="left-span text-lg-right" style="line-height: 1"
            >{{ currentPoints }} pts
            <p style="font-size: small">( {{ currentBalance }} SAR )</p>
          </span>
        </v-row>
      </div>
      <v-row>
        <!-- <template> -->
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#5a86ff"
                style="color: #ffffff; text-transform: capitalize"
                class="btn"
                v-bind="attrs"
                v-on="on"
                v-if="activateRecharge"
                :disabled="wallet_status == 'Freeze'"
              >
                Recharge
              </v-btn>
            </template>
            <v-card>
              <!-- cancel button -->
              <div class="border d-table w-100">
                <v-card-title>
                  <span>
                    <h1 class="form-title">
                      {{ $t(formTitle) }}
                    </h1>
                  </span>
                </v-card-title>
                <div class="d-table-cell tar" style="padding-right: 5px">
                  <v-icon large @click="close" class="main-color" title="cancel"
                    >cancel
                  </v-icon>
                </div>
              </div>

              <v-card-text>
                <v-container>
                  <v-form
                    v-model="valid"
                    ref="modalForm"
                    class="py-4 modal-form"
                  >
                    <v-row v-if="!amountFilled">
                      <v-col cols="12" sm="5" md="5">
                        <label>{{ $t("Amount") }} </label>
                        <v-text-field
                          v-model="chargeDetails.amount"
                          :rules="[
                            validationRules.required,
                            validationRules.integersOrDecimals,
                          ]"
                          solo
                          @keyup="calculatePoints"
                        ></v-text-field>
                      </v-col>
                      <!-- impty Space -->
                      <v-col cols="12" sm="2" md="2"> </v-col>
                      <!-- impty Space -->
                      <v-col cols="12" sm="5" md="5">
                        <label>{{ $t("Value in Points") }} </label>
                        <v-text-field
                          v-model="calculated_points"
                          readonly
                          solo
                          >{{ calculated_points }}</v-text-field
                        >
                      </v-col>
                    </v-row>
                    <v-row v-if="amountFilled">
                      <v-col cols="12" md="12">
                        <div class="mysr-form"></div>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions v-if="!amountFilled" style="padding-bottom: 30px">
                <v-spacer></v-spacer>

                <v-btn
                  style="text-transform: capitalize"
                  class="modal-btn-save"
                  @click="recharge"
                  >{{ $t("Confirm") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-btn
          class="refund-request"
          v-if="activateRecharge"
          @click="confirm_refund_dialog = true"
          :disabled="
            currentBalance <= 0 ||
            currentPoints <= 0 ||
            wallet_status == 'Freeze'
          "
        >
          Refund Request
        </v-btn>
        <!-- =============================== confirm dialog ============= -->
        <v-row justify="center">
          <v-dialog v-model="confirm_dialog" max-width="500">
            <v-card>
              <v-card-title class="text-h6"> Are you sure ? </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="alert-cancel-btn"
                  text
                  @click="confirm_dialog = false"
                >
                  No
                </v-btn>
                <v-btn
                  class="alert-save-btn"
                  text
                  @click="
                    confirm_dialog = false;
                    dialog = false;
                  "
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <!-- =============================== confirm dialog ============= -->
        <v-row justify="center">
          <v-dialog v-model="confirm_refund_dialog" max-width="300">
            <v-card>
              <v-card-title
                class="text-h6 custom-header main-color font-weight-bold"
              >
                Refund Request
              </v-card-title>
              <v-card-text class="font-weight-normal reverse-dialog"
                >Are you sure you want to request a refund for your current
                balance?</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="alert-cancel-btn"
                  text
                  @click="confirm_refund_dialog = false"
                >
                  No
                </v-btn>
                <v-btn class="alert-save-btn" text @click="refundWallet()">
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <!-- =============================== confirm dialog ============= -->
        <!-- </template> -->
      </v-row>
    </div>
    <!-- =============================V_SNACK BAR=================================== -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snack_color"
      rounded="pill"
      top
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- =============================V_SNACK BAR=================================== -->
  </v-col>
</template>

<script>
import { validationMixin } from "../mixins/validation-mixin";
import HttpService from "../services/HttpService.js";
// import ACL from "../acl.js";

const PUBLISHABLE_KEY = process.env.VUE_APP_PUBLISHABLE_KEY;

export default {
  mixins: [validationMixin],
  name: "MyWallet",
  props: [
    "currentBalance",
    "activateRecharge",
    "studentID",
    "currentPoints",
    "wallet_status",
  ],
  data() {
    return {
      formTitle: "Payment Details",
      dialog: false,
      confirm_dialog: false,
      confirm_refund_dialog: false,
      valid: false,
      loading: false,
      status_of_wallet: "",
      paymentId: "",
      message: "",
      snackbar: false,
        timeout: 10000,
      snack_color: "#ffffff",
      amountFilled: false,
      chargeDetails: {
        amount: "",
      },
      defaultItem: {
        amount: "",
      },
      validation_errors: {
        amount: [],
      },
      isStudent: localStorage.getItem("selectedRole").includes("Student"),
      calculated_points: 0,
    };
  },
  methods: {
    setChargeDetails() {
      this.status_of_wallet = this.wallet_status;
      console.log("this.status_of_wallet = ", this.status_of_wallet);

      this.$nextTick(() => {
        this.chargeDetails = Object.assign({}, this.defaultItem);
      });
      this.validation_errors = Object.assign(
        {},
        {
          amount: [],
        }
      );
    },

    getStudentBalance() {
      //This will work if and only if the authanticated person is a STUDENT not a PARENT
      if (this.isStudent) {
        HttpService.get(
          "wallet/Studentwallet/" + this.studentID,
          "",
          true
        ).then((response) => {
          console.log("walet res = ", response);
          this.currentBalance = Number(response.data.data.balance).toFixed(2);
          this.currentPoints = Number(response.data.data.points).toFixed(2);
        });
      }
    },

    onFocus() {
      this.$refs.modalForm.resetValidation();
    },

    close() {
      this.amountFilled = false;
      this.calculated_points = 0;
      this.paymentId = "";
      this.$nextTick(() => {
        this.chargeDetails = Object.assign({}, this.defaultItem);
      });
      this.$refs.modalForm.resetValidation();
      this.dialog = false;
    },

    closeMoyaser() {
      this.dialog = false;
    },

    recharge() {
      if (this.valid) {
        // send the amount to backend
        HttpService.post(
          "wallet/recharge",
          { amount: this.chargeDetails.amount },
          true
        ).then((response) => {
          // console.table(response.data.data);
          this.amountFilled = true;
          this.paymentId = response.data.data.paymentId;
          setTimeout(() => {
            this.initMoyasarForm();
          }, 500);
        });
      } else {
        this.$refs.modalForm.validate();
      }
    },

    refundWallet() {
      HttpService.get("wallet/refundMoneyWallet/", "", true).then(
        (response) => {
          console.log("walet res = ", response);
          this.message = response.data.status.message;

          if (response.data.status.error == false) {
            this.confirm_refund_dialog = false;
            console.log("message = ", this.message);

            this.confirm_dialog = false;
            this.snack_color = "success";
            this.snackbar = true;
            // window.location.reload();
            setTimeout(function () {
              window.location.reload();
            }, 10000);
          }
        }
      );
    },

    initMoyasarForm() {
      const _this = this;
      // eslint-disable-next-line no-undef
      Moyasar.init({
        element: ".mysr-form",
        // Amount in the smallest currency unit.
        // For example:
        // 10 SAR = 10 * 100 Halalas
        // 10 KWD = 10 * 1000 Fils
        // 10 JPY = 10 JPY (Japanese Yen does not have fractions)
        amount: _this.chargeDetails.amount * 100,
        currency: "SAR",
        description: "Recharge",
        publishable_api_key: PUBLISHABLE_KEY,
        callback_url:
          "http://" +
          window.location.host +
          "/payment-redirect/" +
          _this.paymentId,
        methods: ["creditcard"],
      });
    },

    calculatePoints() {
      /^(0|[1-9]\d*)(\.\d+)?$/.test(this.chargeDetails.amount) === true
        ? (this.calculated_points = this.chargeDetails.amount * 20)
        : (this.calculated_points = 0);
    },
  },

  created() {
    this.getStudentBalance();
  },
  mounted() {
    this.setChargeDetails();
  },

  watch: {},

  computed: {},
};
</script>

<style scoped>
.cart-title {
  width: 438px;
  height: 23px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  /* web blue */

  color: #7297ff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.wallet-container {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-end; */

  /* position: absolute; */
  /* margin-left: 20px; */
  gap: 35px;
  padding: 24px;
  width: 486px;
  height: 260px;
  /* left: 1188px; */
  /* top: 121px; */
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.wallet-inside-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
  gap: 17px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 438px;
  height: 65px;
  background: #ecf1ff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.suitable {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  width: 406px;
  height: 33px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.right-span {
  width: 138px;
  height: 23px;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #43425d;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.left-span {
  width: 200px;
  height: 33px;
  right: inherit;
  /* font-family: "Open Sans"; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 33px;

  color: #43425d;

  flex: none;
  order: 1;
  flex-grow: 0;
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
  gap: 10px;
  cursor: pointer;
  width: 438px;
  height: 43px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  background: #5a86ff;
  border-radius: 4px;
}

.form-title {
  color: #7297ff !important ;
  width: 165px !important;
  height: 27px !important;

  /* font-family: "Open Sans" !important; */
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  /* line-height: 27px !important; */
  flex: none;
  order: 0;
  flex-grow: 0;
}

/* .border {
  border: 2px indigo dashed;
} */

.d-table {
  display: table;
}

.d-table-cell {
  display: table-cell;
}

.w-100 {
  width: 100%;
}

.tar {
  text-align: right;
}

.refund-request {
  color: #5a86ff;
  text-transform: capitalize;
  border: none !important;
  margin: 15px !important;
}
</style>
