import VueRouter from "vue-router";
import Vue from "vue";

import HomeView from "../views/Home.vue";
import LoginView from "../views/Login.vue";
import SettingView from "../views/Settings.vue";
import Canteen from "../views/Canteen.vue";
import staff from "../views/Staff.vue";
import Product from "../views/Product.vue";
import Stores from "../views/Stores.vue";
import Orders from "../views/Orders.vue";
import Wallet from "../views/ParentWallet.vue";
import OrdersHistory from "../views/OrdersHistory.vue";
import OrderDetails from "../views/OrderDetails.vue";
import PyamentRedirect from "../views/PyamentRedirect.vue";
import Transfers from "../views/Transfers.vue";
import TransfersForAccountant from "../views/TransfersForAccountant.vue";
import RefundRequests from "../views/RefundRequests.vue";
import ACL from "../acl.js";

const routes = [
  {
    path: "/login",
    component: LoginView,
  },
  {
    path: "/",
    component: HomeView,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/school",
    component: SettingView,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Super-Admin")) {
          next();
        } else {
          //not Authorized 403 you need to make this page
          next("/login"); //
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/canteens",
    component: Canteen,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Super-Admin")) {
          next();
        } else {
          //not Authorized 403 you need to make this page
          next("/login"); //
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/Staff-Members",
    component: staff,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Super-Admin") || ACL.Role("Student")) {
          next();
        } else {
          //not Authorized 403 you need to make this page
          next("/login"); //
        }
      } else {
        next("/login");
      }
    },
  },

  {
    path: "/productData",
    component: Product,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Warehouse Staff") || ACL.Role("Super-Admin")) {
          next();
        } else {
          //not Authorized 403 you need to make this page
          next("/login"); //
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/quantities",
    component: Stores,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Warehouse Staff") || ACL.Role("Super-Admin")) {
          next();
        } else {
          //not Authorized 403 you need to make this page
          next("/login"); //
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/orders",
    component: Orders,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Canteen Staff") || ACL.Role("Super-Admin")) {
          next();
        } else {
          //not Authorized 403 you need to make this page
          next("/login"); //
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/points",
    component: Wallet,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Parent") || ACL.Role("Super-Admin")) {
          next();
        } else {
          //not Authorized 403 you need to make this page
          next("/login"); //
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/OrdersHistory/:id",
    component: OrdersHistory,
    props: true,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (
          ACL.Role("Student") ||
          ACL.Role("Super-Admin") ||
          ACL.Role("Parent")
        ) {
          next();
        } else {
          //not Authorized 403 you need to make this page
          next("/login"); //
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/OrderDetails/:order",
    component: OrderDetails,
    props: true,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (
          ACL.Role("Student") ||
          ACL.Role("Super-Admin") ||
          ACL.Role("Parent")
        ) {
          next();
        } else {
          //not Authorized 403 you need to make this page
          next("/login"); //
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/payment-redirect/:paymentId",
    component: PyamentRedirect,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/Transfers",
    component: Transfers,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/Recharges",
    component: TransfersForAccountant,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/RefundRequests",
    component: RefundRequests,
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Accountant")) {
          next();
        } else {
          //not Authorized 403 you need to make this page
          next("/login"); //
        }
      }
    },
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes, // short for `routes: routes`
});

export default router;
